import EnvUtility from "./EnvUtility";

class LocalStorageUtility {
  static set(key, value) {
    if (EnvUtility.isServerSide) return false;
    localStorage.setItem(key, value);
    return true;
  }

  static get(key) {
    if (EnvUtility.isServerSide) return null;
    return localStorage.getItem(key);
  }

  static remove(key) {
    if (EnvUtility.isServerSide) return false;
    localStorage.removeItem(key);
    return true;
  }

  static clear() {
    if (EnvUtility.isServerSide) return false;
    localStorage.clear();
    return true;
  }
}

export default LocalStorageUtility;
