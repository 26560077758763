import NextImage, { ImageProps } from "next/image";
import { useCallback, useState } from "react";
import { ImagePropsCommon } from "./typings";

type Props = ImageProps & ImagePropsCommon;

function ImageWithPlaceholder({ src, withPlaceholder, lazyLoad, loading, ...rest }: Props) {
  const [isLoaded, setLoaded] = useState(false);

  const onLoadingComplete = useCallback(() => setLoaded(true), []);
  return (
    <NextImage
      src={isLoaded ? src : withPlaceholder}
      onLoadingComplete={onLoadingComplete}
      loading={lazyLoad ? "lazy" : "eager"}
      {...rest}
    />
  );
}

export { ImageWithPlaceholder };
