import { ImageFormat } from "generated/graphql/types";
import { ImageWithPlaceholder } from "./ImageWithPlaceholder";
import NextImage from "next/image";
import { PQNextImageType } from "./typings";
import { RootState } from "@/commons/redux/reducers/stores";
import { useSelector } from "react-redux";

const getBestFormat = (formats: ImageFormat[], formatType: string): string => {
  const exts = formatType ? [formatType] : ["large", "medium"];
  let currentFormat = formats[0];

  exts?.forEach((ext) => {
    const extFound = formats.find((format) => format.type === ext);
    if (extFound) currentFormat = extFound;
  });

  //if (!currentFormat) throw new Error("Image format not found");

  if (!currentFormat) return null;

  return `https://data.promoqui.it/${currentFormat.url}`;
};

const PQNextImage = (props: PQNextImageType) => {
  const { src, formats, webFormat, withPlaceholder, lazyLoad, ...rest } = props;

  const { ccontext } = useSelector((state: RootState) => state);
  const { rStateIsChromeLighthouse, rStateIsBot } = ccontext;

  let bestSrc = src;

  if (formats) bestSrc = getBestFormat(formats, webFormat);

  if (!bestSrc) return null;

  let myLoader = null;

  // To avoid error on images by rendertron (using slackbot)
  if (rStateIsBot && !rStateIsChromeLighthouse) {
    myLoader = ({ src, _width, _quality }) => {
      return src;
    };
  }

  if (withPlaceholder && lazyLoad)
    return (
      <ImageWithPlaceholder
        src={bestSrc}
        loader={myLoader}
        withPlaceholder={withPlaceholder}
        lazyLoad={lazyLoad}
        {...rest}
      />
    );

  return (
    <NextImage
      src={bestSrc}
      loader={myLoader}
      loading={lazyLoad ? "lazy" : "eager"}
      {...rest}
    />
  );
};

export default PQNextImage;
