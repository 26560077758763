import { useCallback, useEffect, useMemo, useRef } from "react";

import { ImageSizeConstants } from "@/commons/dataset/constants/ImageSizeConstants";
import InteractiveLabel from "@/commons/ui/components/interactive-label/InteractiveLabel";
import IntersectionLeafletUtility from "@/commons/utilities/IntersectionLeafletUtility";
import { LeafletTypeConstants } from "@/commons/dataset/constants/LeafletConstats";
import LeafletUtility from "@/commons/utilities/LeafletUtility";
import LocationUtility from "@/commons/utilities/LocationUtility";
import Overlay from "@/commons/ui/components/overlay/Overlay";
import PQIcon from "@/commons/ui/components/pq-icon/PQIcon";
import PQImg from "@/commons/ui/components/pq-img/PQImg";
import PQNextImage from "../pq-next-image";
import PqConsole from "@/commons/utilities/PqConsole";
import Sticker from "@/commons/ui/components/sticker/Sticker";
import Tracking from "@/commons/tracking/Tracking";
import { ccontextSelector } from "@/commons/redux/selectors/ccontext";
import styles from "./LeafletCover.module.scss";
import usePQTranslations from "@/commons/hooks/usePQTranslations";
import { useSelector } from "react-redux";
import { useSelectorPQGlobalThis } from "@/commons/hooks/usePQGlobalThis";
import ImageModel from "@/commons/dataset/models/ImageModel";

interface Props {
  lazyLoad: boolean;
  leaflet: any;
  position: any;
}

const LeafletCover = ({ lazyLoad = true, leaflet, position }: Props) => {
  const { pqImages } = useSelectorPQGlobalThis();

  const {
    rStateLocationHref,
    rStateHeaders,
    rStateDeviceUser,
    rStateAppRender,
  } = useSelector(ccontextSelector);

  const t = usePQTranslations();
  const contentCoverRef = useRef();
  const isVisited = LeafletUtility.isVisited(leaflet);
  const userLocation = LocationUtility.getUserLocation();

  useEffect(() => {
    if (!contentCoverRef.current) return;
    IntersectionLeafletUtility.addElement(
      leaflet,
      contentCoverRef.current,
      position,
      rStateAppRender,
    );
  }, [leaflet, position, rStateAppRender]);

  const getClassesLeafletCover = useMemo(() => {
    let classes = [styles.leafletCover];
    if (leaflet.isExpiring) classes.push(styles.leafletCoverIsExpiring);
    if (leaflet.isPreview) classes.push(styles.leafletCoverIsPreview);
    if (leaflet.isCoupon) classes.push(styles.leafletCoverIsCoupon);
    return classes.join(" ");
  }, [leaflet.isExpiring, leaflet.isPreview, leaflet.isCoupon]);

  const clickOnCover = useCallback(() => {
    LeafletUtility.setVisited(leaflet);

    const urlLeafletParams = {
      leafletType: leaflet.type,
      leafletExternalUrl: leaflet.externalUrl,
      leafletSlug: leaflet.slug,
      retailerSlug: leaflet.retailer.slug,
    };
    const urlLeaflet = LeafletUtility.clickToCoverLeaflet(urlLeafletParams);

    if (
      (leaflet.type == LeafletTypeConstants.S2S ||
        leaflet.type == LeafletTypeConstants.CLICK_TO_STORE_LEAFLET) &&
      leaflet.shopfullyId
    ) {
      let windowReferences = null;
      if (leaflet.type == LeafletTypeConstants.CLICK_TO_STORE_LEAFLET) {
        windowReferences = window.open();
      }

      const paramsTrack = {
        leafletId: leaflet.id,
        leafletSFId: leaflet.shopfullyId || `PQ-${leaflet.id}`,
        leafletTitle: leaflet.S2SCampaign
          ? leaflet.S2SCampaign?.title
          : leaflet.title,
        userLocation: userLocation,
        rStateLocationHref: rStateLocationHref,
        rStateHeaders: rStateHeaders,
        rStateDeviceUser: rStateDeviceUser,
        rStateAppRender: rStateAppRender,
      };

      Tracking.trackS2SOfferView(paramsTrack, {
        SF: leaflet.shopfullySendEvents,
      })
        .catch((error) => {
          PqConsole.error("Tracking.trackS2SOfferView", {
            ...error,
            shopfullySendEvents: leaflet.shopfullySendEvents,
          });
        })
        .finally(() => {
          if (windowReferences) {
            windowReferences.location = urlLeaflet.url;
          } else {
            window.open(urlLeaflet.url, "_self");
          }
        });
    } else {
      window.open(urlLeaflet.url, "_self");
    }
  }, [
    leaflet.S2SCampaign,
    leaflet.externalUrl,
    leaflet.id,
    leaflet.retailer.slug,
    leaflet.shopfullyId,
    leaflet.shopfullySendEvents,
    leaflet.slug,
    leaflet.title,
    leaflet.type,
    rStateAppRender,
    rStateDeviceUser,
    rStateHeaders,
    rStateLocationHref,
    userLocation,
  ]);

  const renderCover = useCallback(() => {
    // a mali estremi estremi rimedi
    const imgModelProps = {
      img:
        leaflet.cover.image || (leaflet.cover && new ImageModel(leaflet.cover)),
      webp:
        leaflet.cover.imageWebP ||
        (leaflet.cover_webp && new ImageModel(leaflet.cover_webp)),
      supportedFormat:
        leaflet.cover.imageWebPSupportedFormats ||
        leaflet.cover_webp_supported_formats,
      format: ImageSizeConstants.MEDIUM,
    };

    if (leaflet.formats) {
      return (
        <div style={{ width: "100%", height: "100%", position: "relative" }}>
          <PQNextImage
            lazyLoad={lazyLoad}
            className={styles.leafletCoverImage}
            alt={leaflet.title}
            formats={leaflet.formats}
            webFormat="large"
            layout="fill"
            objectFit="contain"
            blurDataURL={pqImages.skeletonLeaflet}
            withPlaceholder={"/images/skeleton_leaflet.png"}
          />
        </div>
      );
    } else {
      return (
        // Da eliminare dopo il refactoring della pagina Retailer
        <div style={{ width: "100%", height: "100%", position: "relative" }}>
          <PQImg
            lazyLoad={lazyLoad}
            imgModel={imgModelProps}
            classNameImage={styles.leafletCoverImage as any}
            blurPlaceholder={pqImages.skeletonLeaflet}
            altText={leaflet.title}
            /*  styleImg={{
              width: "100",
              // height: "100%",

              objectFit: "contain",
            }} */
          />
        </div>
      );
    }
  }, [lazyLoad, leaflet, pqImages.skeletonLeaflet]);

  const renderSticker = useCallback(() => {
    let sticker;

    if (leaflet.isPreview) {
      sticker = <Sticker blue>{t("site.preview")}</Sticker>;
    } else if (leaflet.isNew) {
      sticker = <Sticker green>{"New"}</Sticker>;
    } else if (leaflet.isExpiring) {
      sticker = <Sticker orange>{t("site.latest_days")}</Sticker>;
    } else if (leaflet.isCoupon) {
      sticker = <Sticker blue>{"Coupon"}</Sticker>;
    }

    if (sticker) {
      sticker = <div className={styles.leafletCoverSticker}>{sticker}</div>;
    }

    return sticker;
  }, [
    leaflet.isPreview,
    leaflet.isNew,
    leaflet.isExpiring,
    leaflet.isCoupon,
    t,
  ]);

  const renderOverlayOpen = useCallback(() => {
    return (
      <div className={styles.leafletCoverOpen}>
        <Overlay>
          <div className={styles.leafletCoverOpenLabel}>{t("site.open")}</div>
        </Overlay>
      </div>
    );
  }, [t]);

  const renderInteractiveLabel = useCallback(() => {
    if (!isVisited && leaflet.isInteractive) {
      return (
        <div className={styles.leafletCoverInteractiveLabel}>
          <InteractiveLabel />
        </div>
      );
    }
  }, [isVisited, leaflet.isInteractive]);

  const renderOverlayGoToSiteOrSeen = useCallback(() => {
    let renderGoToSite = false;
    if (
      leaflet.type == "ClickToStoreLeaflet" &&
      leaflet.__typename !== "OfferContainer"
    ) {
      renderGoToSite = true;
    }

    if (renderGoToSite || isVisited) {
      return (
        <div className={styles.leafletCoverVisited}>
          <Overlay showOnMouseOver={false} show>
            {renderGoToSite ? (
              <>
                <PQIcon icon={"icon-external-link"} />
                <span>{t("site.go_to_site")}</span>
              </>
            ) : isVisited ? (
              <>
                <PQIcon icon={"check"} />
                <span>{t("site.seen")}</span>
              </>
            ) : null}
          </Overlay>
        </div>
      );
    }
  }, [isVisited, leaflet.__typename, leaflet.type, t]);

  return (
    <div className={getClassesLeafletCover} ref={contentCoverRef}>
      <div className={styles.leafletCoverContent} onClick={clickOnCover}>
        {renderCover()}
        {renderSticker()}
        {renderOverlayOpen()}
        {renderOverlayGoToSiteOrSeen()}
      </div>
      {renderInteractiveLabel()}
    </div>
  );
};

LeafletCover.IMAGE_SIZES_RATIO = 0.8;

export default LeafletCover;
