import { useMemo } from "react";
import styles from "./LeafletFooter.module.scss";
import PQIcon from "@/commons/ui/components/pq-icon/PQIcon";
import DateTimeUtility from "@/commons/utilities/DateTimeUtility";
import { Box } from "@mui/material";

interface Props {
  retailerName: string;
  title: string;
  endDate: string;
  distance?: string;
  isAdv?: boolean;
}

const LeafletFooter = ({
  retailerName,
  title,
  endDate,
  distance,
  isAdv = false,
}: Props) => {
  const validTill = useMemo(() => {
    if (isAdv) return endDate;
    return DateTimeUtility.validsUntil(endDate);
  }, [endDate, isAdv]);

  return (
    <Box maxWidth={230} className={styles.leafletFooter}>
      <div className={styles.leafletFooterTitle}>{retailerName}</div>
      <div className={styles.leafletFooterSubtitle}>{title}</div>
      <div
        className={`${styles.leafletFooterDateanddistance} ${
          DateTimeUtility.isAboutToExpire(endDate) &&
          styles.leafletFooterDateanddistanceExpiring
        }`}
      >
        <h3 className={styles.leafletFooterLocation}>
          <PQIcon icon={"calendar"} />
          {validTill}
        </h3>
        {distance && (
          <h3 className={styles.leafletFooterLocation}>
            <PQIcon icon={"map-marker-alt"} />
            {distance}
          </h3>
        )}
      </div>
    </Box>
  );
};

export default LeafletFooter;
