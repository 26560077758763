import React, { useMemo } from "react";
import styles from "./Sticker.module.scss";

const Sticker = ({
  blue,
  green,
  orange,
  primary,
  warning,
  danger,
  custom,
  info,
  success,
  oppositeRotate,
  children,
}) => {
  const getClassesLeafletCover = useMemo(() => {
    let classes = [styles.sticker];

    if (oppositeRotate) classes.push("oppositeRotate");
    if (primary) classes.push(styles.stickerPrimary);
    if (success) classes.push(styles.stickerSuccess);
    if (warning) classes.push(styles.stickerWarning);
    if (info) classes.push(styles.stickerInfo);
    if (danger) classes.push(styles.stickerDanger);
    if (custom) classes.push(custom);
    if (blue) classes.push(styles.stickerBlue);
    if (green) classes.push(styles.stickerGreen);
    if (orange) classes.push(styles.stickerOrange);

    return classes.join(" ");
  }, [
    blue,
    green,
    orange,
    primary,
    warning,
    danger,
    custom,
    info,
    success,
    oppositeRotate,
  ]);

  return <div className={getClassesLeafletCover}>{children}</div>;
};

Sticker.defaultProps = {
  blue: false,
  green: false,
  orange: false,
  primary: false,
  warning: false,
  danger: false,
  custom: false,
  info: false,
  success: false,
  oppositeRotate: false,
};

export default Sticker;
