import React from "react";
import styles from "./InteractiveLabel.module.scss";
import usePQTranslations from "@/commons/hooks/usePQTranslations";
import PQIcon from "@/commons/ui/components/pq-icon/PQIcon";

const InteractiveLabel = () => {
  const t = usePQTranslations();

  return (
    <div className={styles.interactiveLabel}>
      <div className={styles.interactiveLabelIcon}>
        <PQIcon icon={"dot-circle"} />
      </div>
      <div className={styles.interactiveLabelText}>{t("site.interactive")}</div>
    </div>
  );
};

export default InteractiveLabel;
