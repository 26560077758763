import React, { useCallback, useMemo, useRef, useState } from "react";

import styles from "./Overlay.module.scss";

const Overlay = ({ show: showProps, showOnMouseOver, onClick, children }) => {
  const nodeRef = useRef();
  const [show, setShow] = useState(showProps);

  const getClassesOverlay = useMemo(() => {
    let classes = [styles.overlay];
    if (show) classes.push(styles.overlayShow);
    return classes.join(" ");
  }, [show]);

  const onMouseOver = useCallback(() => {
    if (showOnMouseOver) setShow(true);
  });

  const onMouseLeave = useCallback(() => {
    if (showOnMouseOver) setShow(false);
  });

  return (
    <div
      className={getClassesOverlay}
      ref={nodeRef}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export default Overlay;

Overlay.defaultProps = {
  show: false,
  showOnMouseOver: true,
  onClick: (_) => {},
};
