// import ObjectUtils from './ObjectUtils';
// import { TypeGQ } from 'GqlCommon';

import Navigator from "./Navigator";
import PqConsole from "./PqConsole";
import LocalStorage from "./LocalStorage";
import usePQRoutes from "@/commons/hooks/usePQRoutes";
import RoutesKeys from "@/commons/keys/RoutesKeys";
import LocalStorageKeys from "@/commons/keys/LocalStorageKeys";
import { LeafletTypeConstants } from "@/commons/dataset/constants/LeafletConstats";
import DistanceUtility from "./DistanceUtility";
import ConvertUtility from "./ConvertUtility";
import includes from "lodash-es/includes";

const routes = usePQRoutes();

class LeafletUtility {
  static optimizedCover(leaflet, format) {
    if (
      leaflet?.cover?.imageWebP &&
      includes(leaflet.cover.imageWebPSupportedFormats, format)
    ) {
      return leaflet.cover.imageWebP.getFormat(format);
    }
    return leaflet.cover.image.getFormat(format);
  }

  static getUrl(retailerSlug, leafletSlug) {
    const url = routes(RoutesKeys.LEAFLET, {
      retailer: retailerSlug,
      id: leafletSlug,
    });
    return url;
  }

  static getUrlWithPage(retailerSlug, leafletSlug, pageNumber) {
    const url = routes(RoutesKeys.LEAFLET_WITH_PAGE, {
      retailer: retailerSlug,
      id: leafletSlug,
      page: pageNumber,
    });
    return url;
  }

  static getUrlWithOffer(retailerSlug, leafletSlug, pageNumber, selectionArea) {
    const url = routes(RoutesKeys.LEAFLET_WITH_OFFER, {
      retailer: retailerSlug,
      id: leafletSlug,
      page: pageNumber,
      selectionArea: selectionArea,
    });
    return url;
  }

  static getUrlWithOfferId(
    retailerSlug,
    leafletSlug,
    pageNumber,
    offerId,
    divisorUrl = "#",
  ) {
    const routesKey = pageNumber
      ? RoutesKeys.LEAFLET_WITH_OFFER_ID
      : RoutesKeys.LEAFLET_WITH_ONLY_OFFER_ID;
    const url = routes(routesKey, {
      retailer: retailerSlug,
      id: leafletSlug,
      page: pageNumber,
      offerId: offerId,
      divisorUrl: divisorUrl,
    });
    return url;
  }

  static getUrlWithCategoryLabel(
    retailerSlug,
    leafletSlug,
    categoryLabel,
    divisorUrl = "#",
  ) {
    const url = routes(RoutesKeys.LEAFLET_WITH_CATEGORY_LABEL, {
      retailer: retailerSlug,
      id: leafletSlug,
      divisorUrl: divisorUrl,
      category: categoryLabel,
    });
    return url;
  }

  static isVisited(leaflet) {
    let isVisited = false;
    try {
      let visitedLeaflets = LocalStorage.get(
        LocalStorageKeys.KEY_VISITED_LEAFLETS,
      );
      if (visitedLeaflets) {
        visitedLeaflets = JSON.parse(visitedLeaflets);
        isVisited = visitedLeaflets.includes(leaflet.id);
      }
    } catch (error) {
      PqConsole.error("leaflet is visited", error);
    } finally {
      return isVisited;
    }
  }

  static setVisited(leaflet) {
    try {
      let visitedLeaflets = LocalStorage.get(
        LocalStorageKeys.KEY_VISITED_LEAFLETS,
      );
      visitedLeaflets = visitedLeaflets ? JSON.parse(visitedLeaflets) : [];
      if (!visitedLeaflets.includes(leaflet.id)) {
        visitedLeaflets.push(leaflet.id);
      }
      LocalStorage.set(
        LocalStorageKeys.KEY_VISITED_LEAFLETS,
        JSON.stringify(visitedLeaflets),
      );
      return true;
    } catch (error) {
      PqConsole.error("leaflet set visited", error);
      return false;
    }
  }

  static setLayout(layout) {
    LocalStorage.set(LocalStorageKeys.KEY_LAYOUT_LEAFLET_VIEWER, layout);
  }

  static initLayout() {
    if (!Navigator.isDesktop(rStateHeaders))
      return LeafletUtility.LAYOUT.HORIZONTAL;
    if (!LocalStorage.getItem(LocalStorageKeys.KEY_LAYOUT_LEAFLET_VIEWER)) {
      LocalStorage.set(
        LocalStorageKeys.KEY_LAYOUT_LEAFLET_VIEWER,
        LeafletUtility.LAYOUT.HORIZONTAL,
      );
    }
    return LocalStorage.get(LocalStorageKeys.KEY_LAYOUT_LEAFLET_VIEWER);
  }

  static clickToCoverLeaflet(params) {
    const { leafletSlug, leafletType, leafletExternalUrl, retailerSlug } =
      params;

    let url = "";
    let target = "_self";

    if (leafletType == LeafletTypeConstants.CLICK_TO_STORE_LEAFLET) {
      url = leafletExternalUrl;
      target = "_blank";
    } else {
      if (retailerSlug && leafletSlug) {
        url = LeafletUtility.getUrl(retailerSlug, leafletSlug);
        if (leafletType == LeafletTypeConstants.S2S) {
          target = "_blank";
        }
      }
    }

    return { target, url };
  }

  static getDistanceWithMeasureUnit(leaflet, location) {
    if (!leaflet.isNational && !location.default) {
      let distance;
      const { store } = leaflet;
      distance = DistanceUtility.getDistanceAB_m(store, location);
      if (!distance) distance = leaflet.distance;

      if (distance) {
        distance = ConvertUtility.distance(distance);
        return `${distance.val} ${distance.unit}`;
      }
    }
    return null;
  }
  //--- old ---

  // static getParametersPageInUrl() {
  // 	let hash2Obj = window.location.hash.replace('#', '');
  // 	hash2Obj = hash2Obj
  // 		.split('&')
  // 		.map((v) => v.split('='))
  // 		.reduce((pre, [key, value]) => ({ ...pre, [key]: value }), {});

  // 	let search2Obj = window.location.search.replace('?', '');
  // 	search2Obj = search2Obj
  // 		.split('&')
  // 		.map((v) => v.split('='))
  // 		.reduce((pre, [key, value]) => ({ ...pre, [key]: value }), {});

  // 	const query2Obj = { ...search2Obj, ...hash2Obj };

  // 	const dataUrl = {};
  // 	if (!ObjectUtils.isEmpty(query2Obj)) {
  // 		dataUrl.pageUrl = query2Obj.p ? (Navigator.isMobile(rStateHeaders) || Navigator.isTablet(rStateHeaders) ? query2Obj.p - 1 : query2Obj.p) : undefined;
  // 		dataUrl.areaUrl = query2Obj.oid;
  // 		dataUrl.categoryUrl = query2Obj.category ? decodeURI(query2Obj.category) : undefined;
  // 	}
  // 	return dataUrl;
  // }
}

LeafletUtility.LAYOUT = {
  HORIZONTAL: "horizontal",
  VERTICAL: "vertical",
};

export default LeafletUtility;
